var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tw-flex tw-w-full tw-h-full" }, [
    _c(
      "div",
      { staticClass: "tw-flex tw-w-full tw-mb-24" },
      [
        _c(
          "ACard",
          {
            staticClass: "tw-mb-0",
            class: _vm.damage ? "tw-w-1/2 tw-pr-16" : "tw-w-full",
            attrs: {
              title: "Dossier " + (_vm.report ? _vm.report.case_number : ""),
              collapse: ""
            },
            scopedSlots: _vm._u(
              [
                _vm.report
                  ? {
                      key: "actions",
                      fn: function() {
                        return [
                          _vm.report.links && _vm.report.links.preview
                            ? _c(
                                "AButton",
                                {
                                  staticClass:
                                    "tw-bg-secondary-400 hover:tw-bg-secondary-300",
                                  attrs: {
                                    "prepend-inner-icon": "picture_as_pdf",
                                    href: _vm.report.links.preview,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(" Bekijk PDF ")]
                              )
                            : _vm._e(),
                          _vm.report.links && _vm.report.links.preview
                            ? _c(
                                "AButton",
                                {
                                  attrs: {
                                    to: {
                                      name: "report.detail",
                                      params: { id: _vm.report.uuid }
                                    },
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(" Bekijk Rapport ")]
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "tw-h-full tw-w-full tw-flex tw-flex-wrap tw-justify-center"
              },
              [
                !_vm.report || _vm.loading
                  ? [
                      _c(
                        "div",
                        { staticClass: "tw-self-center tw-text-center" },
                        [
                          _vm.loading ? _c("ALoader") : _vm._e(),
                          !_vm.report && !_vm.loading
                            ? [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "tw-block tw-text-black-300 tw-text-7xl tw-mb-16"
                                  },
                                  [_vm._v("error_outline")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "tw-block tw-text-black-300 tw-text-lg tw-font-semibold"
                                  },
                                  [
                                    _vm._v(
                                      " De geselecteerde afwijking heeft geen dossier. "
                                    )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm._e(),
                !_vm.loading && _vm.report
                  ? [
                      _c(
                        "div",
                        { staticClass: "tw-w-full tw-flex" },
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "tw-bg-white",
                              attrs: { "slider-color": "secondary" },
                              model: {
                                value: _vm.activeReportTab,
                                callback: function($$v) {
                                  _vm.activeReportTab = $$v
                                },
                                expression: "activeReportTab"
                              }
                            },
                            [
                              _vm._l(_vm.reportTabs, function(tab, index) {
                                return [
                                  !tab.hidden
                                    ? _c(
                                        "v-tab",
                                        {
                                          key: index,
                                          staticClass: "tab__filled",
                                          class: {
                                            active:
                                              _vm.activeReportTab === tab.key
                                          },
                                          attrs: { href: "#" + tab.key }
                                        },
                                        [_vm._v(" " + _vm._s(tab.name) + " ")]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          staticClass:
                            "panelContent tw-h-full tw-w-full panelContent tw-overflow-y-auto tw-overflow-x-hidden",
                          model: {
                            value: _vm.activeReportTab,
                            callback: function($$v) {
                              _vm.activeReportTab = $$v
                            },
                            expression: "activeReportTab"
                          }
                        },
                        [
                          _c("div", { staticClass: "tw-h-full tw-p-16" }, [
                            _c(
                              "div",
                              {
                                staticClass: "tw-p-16",
                                class: {
                                  "tw-hidden": _vm.activeReportTab !== "info"
                                }
                              },
                              [
                                _c("LabelValue", {
                                  staticClass: "tw-mb-16",
                                  attrs: { label: "Status" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "tw-mt-8" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      "statusPill statusBackgroundColor--" +
                                                      _vm.getStatusColor(
                                                        _vm.report.status
                                                      )
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.report.status
                                                            .length
                                                            ? _vm.getStatusLabel(
                                                                _vm.report
                                                                  .status
                                                              )
                                                            : ""
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    2039809638
                                  )
                                }),
                                _c("LabelValue", {
                                  attrs: { label: "Gebied" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            _vm.report.risk_profile.gasveld &&
                                            _vm.report.risk_profile.gasveld
                                              .length > 1
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.report.risk_profile
                                                          .gasveld
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : _vm.report.risk_profile
                                                  .gasveld_6km &&
                                                _vm.report.risk_profile
                                                  .gasveld_6km.length > 1
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.report.risk_profile
                                                          .gasveld_6km
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : [_vm._v("Overig effectgebied")]
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    618236544
                                  )
                                }),
                                _c("LabelValue", {
                                  attrs: {
                                    label: "# aardbevingen met een PGV >2.0",
                                    value: _vm.report.risk_profile
                                      ? _vm.report.risk_profile
                                          .earthquakes_accountable
                                      : "-"
                                  }
                                }),
                                _c("LabelValue", {
                                  attrs: { label: "Zwaarste Aardbeving" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            _vm.report.risk_profile &&
                                            _vm.report.risk_profile.heaviest
                                              ? [
                                                  _vm._v(
                                                    " M" +
                                                      _vm._s(
                                                        _vm.report.risk_profile
                                                          .heaviest.magnitude
                                                      ) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.report.risk_profile
                                                          .heaviest.city
                                                      ) +
                                                      " op " +
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          _vm.report
                                                            .risk_profile
                                                            .heaviest
                                                            .occurred_at
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    2929892208
                                  )
                                }),
                                _c(
                                  "div",
                                  { staticClass: "tw-flex tw-justify-start" },
                                  [
                                    _c("LabelValue", {
                                      attrs: { label: "Max. PGV 25%" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "value",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "tw-mt-8" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          "statusPill statusBackgroundColor--secondary"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.report
                                                                .risk_profile
                                                                .pgv_25pct
                                                                ? _vm.report
                                                                    .risk_profile
                                                                    .pgv_25pct
                                                                : "-"
                                                            ) +
                                                            " mm/s "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        4171822642
                                      )
                                    }),
                                    _c("LabelValue", {
                                      attrs: { label: "Max. PGV 1%" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "value",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "tw-mt-8" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          "statusPill statusBackgroundColor--primary"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.report
                                                                .risk_profile
                                                                .pgv_1pct
                                                                ? _vm.report
                                                                    .risk_profile
                                                                    .pgv_1pct
                                                                : "-"
                                                            ) +
                                                            " mm/s "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        1451106756
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _c("LabelValue", {
                                  attrs: {
                                    label: "Workflow",
                                    value: _vm.report.workflow
                                  }
                                }),
                                _c("LabelValue", {
                                  attrs: {
                                    label: "Opname variant",
                                    value: _vm.report.opname_variant
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "tw-flex tw-w-full" },
                                  [
                                    _c("LabelValue", {
                                      attrs: {
                                        label: "Rapport type",
                                        value: _vm.report.type
                                          ? _vm.report.type.name
                                          : ""
                                      }
                                    }),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _vm.report.is_special
                                                    ? _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "special-icon"
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("star")]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3472463914
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Dit is een special dossier")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("LabelValue", {
                                  attrs: {
                                    label: "Rapportage classificatie",
                                    value: _vm.report.management_info_class
                                  }
                                }),
                                _c("LabelValue", {
                                  attrs: {
                                    label: "Planning workflow",
                                    value: _vm.report.planning_workflow
                                  }
                                }),
                                _c("LabelValue", {
                                  attrs: { label: "Calculatiemodel" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            _vm.report.calculation_model
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.report
                                                          .calculation_model
                                                          .name
                                                      ) +
                                                      " (" +
                                                      _vm._s(
                                                        _vm.report
                                                          .calculation_model
                                                          .from
                                                      ) +
                                                      ") " +
                                                      _vm._s(_vm.getVatText()) +
                                                      " "
                                                  )
                                                ]
                                              : [_vm._v("-")]
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1831177274
                                  )
                                }),
                                _c("LabelValue", {
                                  attrs: { label: "Opnamedatum" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("dateFormatTime")(
                                                    _vm.report.planned_at ===
                                                      _vm.databaseDate
                                                      ? "-"
                                                      : _vm.report.planned_at
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    590431908
                                  )
                                }),
                                _c("LabelValue", {
                                  attrs: { label: "Aanvrager" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            _vm.report.applicant
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.report.applicant
                                                          .name
                                                      )
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _vm.report.applicant.phone
                                                      ? _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                "tel:" +
                                                                _vm.report
                                                                  .applicant
                                                                  .phone
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.report
                                                                    .applicant
                                                                    .phone
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                ]
                                              : [_vm._v("-")]
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    491749741
                                  )
                                }),
                                _c("LabelValue", {
                                  attrs: { label: "Intermediair" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            _vm.report.intermediary
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.report.intermediary
                                                          .name
                                                      )
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _vm.report.intermediary
                                                      .phone
                                                      ? _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                "tel:" +
                                                                _vm.report
                                                                  .intermediary
                                                                  .phone
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.report
                                                                    .intermediary
                                                                    .phone
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                ]
                                              : [_vm._v("-")]
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1072855674
                                  )
                                }),
                                _c("LabelValue", {
                                  attrs: { label: "Adres" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            _vm.report.address
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.report.address
                                                          .street
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.report.address
                                                          .number
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.report.address
                                                          .number_add
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.report.address
                                                          .postcode
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.report.address.city
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    673174372
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tw-relative tw-h-full tw-w-full tw-overflow-hidden",
                                class: {
                                  "tw-hidden": _vm.activeReportTab !== "map"
                                }
                              },
                              [
                                _vm.hasValidAddress
                                  ? [
                                      _c(
                                        "gmap-map",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%"
                                          },
                                          attrs: {
                                            center: {
                                              lat:
                                                _vm.report.address.geolocation
                                                  .latitude,
                                              lng:
                                                _vm.report.address.geolocation
                                                  .longitude
                                            },
                                            zoom: 10,
                                            options: {
                                              zoomControl: true,
                                              mapTypeControl: false,
                                              scaleControl: false,
                                              streetViewControl: true,
                                              rotateControl: true,
                                              fullscreenControl: true,
                                              disableDefaultUi: false
                                            }
                                          }
                                        },
                                        [
                                          _c("GmapMarker", {
                                            attrs: {
                                              position: {
                                                lat:
                                                  _vm.report.address.geolocation
                                                    .latitude,
                                                lng:
                                                  _vm.report.address.geolocation
                                                    .longitude
                                              },
                                              clickable: true,
                                              draggable: true
                                            }
                                          }),
                                          _c("GmapMarker", {
                                            attrs: {
                                              position: _vm.report.position,
                                              draggable: false,
                                              clickable: true,
                                              title: _vm.report.name
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        ),
        _vm.damage
          ? [
              _c(
                "ACard",
                {
                  staticClass: "tw-w-1/2 tw-pl-16 tw-h-full",
                  attrs: { title: "Schade", collapse: "" },
                  scopedSlots: _vm._u(
                    [
                      _vm.report
                        ? {
                            key: "actions",
                            fn: function() {
                              return [
                                _vm.report.links && _vm.report.links.preview
                                  ? _c(
                                      "AButton",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.navigateToDamage(
                                              _vm.report.uuid
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Bekijk schade ")]
                                    )
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm.loading
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-h-full tw-w-full tw-flex tw-flex-wrap tw-justify-center"
                          },
                          [_c("ALoader", { staticClass: "tw-self-center" })],
                          1
                        )
                      ]
                    : _vm._e(),
                  !_vm.damage && !_vm.loading
                    ? [
                        _c(
                          "div",
                          { staticClass: "tw-flex tw-w-full tw-h-full" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tw-self-center tw-text-center tw-w-full"
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "tw-text-black-300 tw-text-7xl tw-mb-16 tw-text-center"
                                  },
                                  [_vm._v("error_outline")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "tw-text-black-300 tw-text-lg tw-font-semibold"
                                  },
                                  [
                                    _vm._v(
                                      " De geselecteerde afwijking heeft geen schade. "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  !_vm.loading
                    ? [
                        _c(
                          "v-tabs",
                          {
                            staticClass: "tw-bg-white",
                            attrs: { "slider-color": "secondary" },
                            model: {
                              value: _vm.activeDamageTab,
                              callback: function($$v) {
                                _vm.activeDamageTab = $$v
                              },
                              expression: "activeDamageTab"
                            }
                          },
                          [
                            _vm._l(_vm.damageTabs, function(tab, index) {
                              return [
                                !tab.hidden
                                  ? _c(
                                      "v-tab",
                                      {
                                        key: index,
                                        staticClass: "tab__filled",
                                        class: {
                                          active:
                                            _vm.activeDamageTab === tab.key
                                        },
                                        attrs: { href: "#" + tab.key }
                                      },
                                      [_vm._v(" " + _vm._s(tab.name) + " ")]
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        ),
                        _c(
                          "v-tabs-items",
                          {
                            staticClass:
                              "tw-h-full panelContent tw-overflow-y-auto tw-overflow-x-hidden",
                            model: {
                              value: _vm.activeDamageTab,
                              callback: function($$v) {
                                _vm.activeDamageTab = $$v
                              },
                              expression: "activeDamageTab"
                            }
                          },
                          [
                            _c("div", { staticClass: "tw-h-full tw-p-16" }, [
                              _c(
                                "div",
                                {
                                  class: {
                                    "tw-hidden":
                                      _vm.activeDamageTab !== "deviant"
                                  }
                                },
                                [
                                  _c("AnomalyCustomProperties", {
                                    staticClass: "tw-text-3xl tw-mb-16",
                                    attrs: { anomaly: _vm.anomaly }
                                  }),
                                  _vm.anomaly.type ===
                                    _vm.AnomalyType.ASSESSMENT_SCHEME &&
                                  _vm.damage &&
                                  _vm.damage.casuality_has_deviated
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-text-base tw-font-bold"
                                          },
                                          [_vm._v("Reden")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tw-text-lg tw-mb-16"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .overwriteConclusionReasonLabels[
                                                    _vm.damage
                                                      .casuality_anomaly_reason
                                                  ]
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-text-base tw-font-bold"
                                          },
                                          [_vm._v("Toelichting")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "tw-text-lg" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.damage
                                                    .casuality_anomaly_explanation
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tw-h-full",
                                  class: {
                                    "tw-hidden":
                                      _vm.activeDamageTab !== "pictures"
                                  }
                                },
                                [
                                  _vm.damage &&
                                  _vm.damage.media &&
                                  _vm.damage.media.length
                                    ? _vm._l(_vm.damage.media, function(
                                        media,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: media.uuid,
                                            staticClass:
                                              "tw-relative tw-h-128 tw-w-128 tw-object-cover tw-inline-block tw-cursor-pointer tw-mx-8"
                                          },
                                          [
                                            _c("v-hover", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var hover = ref.hover
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tw-h-full tw-w-full"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: hover,
                                                                  expression:
                                                                    "hover"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "imageOverlay"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "imageOverlay__action",
                                                                  nativeOn: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openImageSlider(
                                                                        index
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " remove_red_eye "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c("img", {
                                                            staticClass:
                                                              "tw-w-full tw-h-full tw-object-cover",
                                                            attrs: {
                                                              src: media.thumb
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      })
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-h-full tw-w-full tw-flex"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tw-w-full tw-text-center tw-self-center"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "tw-block tw-text-black-300 tw-text-7xl tw-mb-16"
                                                  },
                                                  [_vm._v("error_outline")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "tw-block tw-text-black-300 tw-text-lg tw-font-semibold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " De geselecteerde schade heeft geen foto's. "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  class: {
                                    "tw-hidden":
                                      _vm.activeDamageTab !== "questionnaire"
                                  }
                                },
                                [
                                  _vm.isLoadingQuestions
                                    ? _c("ALoader", {
                                        staticClass:
                                          "tw-w-full tw-h-full tw-my-16"
                                      })
                                    : _vm._e(),
                                  !_vm.isLoadingQuestions && _vm.damageQuestions
                                    ? _c("Questionnaire", {
                                        attrs: {
                                          questions: _vm.damageQuestions,
                                          answers: _vm.damage.answers,
                                          report: _vm.report,
                                          damage: _vm.damage,
                                          disabled: true,
                                          readOnly: true
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  class: {
                                    "tw-hidden":
                                      _vm.activeDamageTab !== "repairs"
                                  }
                                },
                                [
                                  _c("RepairTable", {
                                    attrs: { damage: _vm.damage }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }