var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ACard",
    { staticClass: "expertPanel", attrs: { title: "Deskundige" } },
    [
      _vm.loading
        ? [
            _vm.loading && !_vm.expert
              ? _c("ALoader", {
                  staticClass: "tw-flex tw-w-full tw-h-full tw-mt-16"
                })
              : _vm._e()
          ]
        : _vm._e(),
      !_vm.loading
        ? [
            !_vm.expert
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "tw-flex tw-w-full tw-h-full tw-text-center tw-py-32"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tw-self-center tw-w-full" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass:
                                "tw-block tw-text-black-300 tw-text-7xl tw-mb-16"
                            },
                            [_vm._v("error_outline")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "tw-block tw-text-black-300 tw-text-lg tw-font-semibold"
                            },
                            [
                              _vm._v(
                                " De geselecteerde afwijking heeft geen expert. "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              : _vm._e(),
            _vm.expert
              ? [
                  _c(
                    "v-layout",
                    { staticClass: "profileContainer", attrs: { row: "" } },
                    [
                      _c("v-flex", { staticClass: "tw-w-full" }, [
                        _c("h1", { staticClass: "profile__userName" }, [
                          _c("span", { staticClass: "text-light" }, [
                            _vm._v(_vm._s(_vm.expert.first_name))
                          ]),
                          _vm._v(" " + _vm._s(_vm.expert.last_name) + " ")
                        ]),
                        _c("span", { staticClass: "profile__userRoles" }, [
                          _vm._v(_vm._s(_vm.userType))
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm.expert
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "profileContainer",
                          attrs: { row: "", wrap: "", "align-center": "" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tw-w-full mb-8" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "email",
                                  label: "Bedrijf mail",
                                  value: _vm.expert.business_email,
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "tw-w-full mb-8" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "email",
                                  label: "Email",
                                  value: _vm.expert.email,
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "tw-w-full mb-8" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "email",
                                  label: "Telefoon",
                                  value: _vm.expert.phone,
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }