import { getUserTypeName, User } from '@/models/User';
import { Avatar } from '@/support/Avatar';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { styles } from '@/support/Style';

@Component<ExpertInfo>({
  components: {

  },
})
export default class ExpertInfo extends Vue {
  @Prop()
  protected expert!: User;

  @Prop({ default: false })
  protected loading!: boolean;

  protected get expertAvatar(): string {
    if (! this.expert) {
      return '';
    }

    const fullName = `${this.expert.first_name} ${this.expert.last_name}`;
    return new Avatar(fullName || 'NA', { color: styles.white, background: styles.primary, size: 512 }).toDataUrl();
  }

  protected get userType(): string {
    if (! this.expert || ! this.expert.type) {
      return '';
    }

    return getUserTypeName(this.expert.type);
  }
}
