import { Anomaly, AnomalyIncludes, AnomalyStatus, AnomalyStatusEnum, AnomalyType, AnomalyTypeLabels } from '@/models/Anomaly';
import ErrorHandler from '@/support/ErrorHandler';
import { Component, Vue } from 'vue-property-decorator';
import AnomalyCustomProperties from '@/components/AnomalyCustomProperties/AnomalyCustomProperties.vue';
import { TableMeta } from '@/components/data-table/DataTable';
import { Report } from '@/models/Report';
import { Damage } from '@/models/Damage';
import { debounce, DebouncedFunc } from 'lodash';
import ExpertInfo from './ExpertInfo/ExpertInfo.vue';
import ReportInfo from './ReportInfo/ReportInfo.vue';

@Component<Monitor>({
  components: {
    ExpertInfo,
    ReportInfo,
    AnomalyCustomProperties,
  },
})
export default class Monitor extends Vue {
  // #region Class properties

  public $pageTitle = 'Monitor tool';

  protected isLoading = false;

  protected isLoadingAnomalies = true;

  protected anomalies: Anomaly[] = [];

  protected selectedAnomaly: Anomaly | null = null;

  protected selectedReport: Report | null = null;

  protected selectedDamage: Damage | null = null;

  protected selectedTypes: AnomalyType[] = [];

  protected showApproved = false;

  protected total = 0;

  protected lastPage = 1;

  protected from = 0;

  protected to = 0;

  protected page = 1;

  protected limit = 20;

  public paginationDebounce: DebouncedFunc<() => void> = this.handlePagination();

  protected anomalyTypes: { label: string, value: string }[] = Object.values(AnomalyType).map((type: string) => (
    { label: AnomalyTypeLabels[`${type}`], value: type }
  ));

  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted(): void {
    this.emitBreadcrumb();
    this.getAnomalies();
  }

  // #endregion

  // #region Class methods

  protected async selectAnomaly(anomaly: Anomaly): Promise<void> {
    this.isLoading = true;
    try {
      this.selectedAnomaly = await new Anomaly().include([AnomalyIncludes.USER, AnomalyIncludes.REPORT, AnomalyIncludes.DAMAGE]).find(anomaly.id);

      if (this.selectedAnomaly) {
        if (this.selectedAnomaly.report && this.selectedAnomaly.report.uuid) {
          this.selectedReport = await new Report().include(['applicant', 'intermediary', 'answers', 'calculation_model', 'tags', 'skills', 'organization', 'statistics', 'jz_user', 'risk_profile', 'job_project', 'project_media', 'archive_info', 'applicant_submissions']).find(this.selectedAnomaly.report.uuid);
        }
        if (this.selectedAnomaly.damage && this.selectedAnomaly.damage.uuid) {
          this.selectedDamage = await new Damage().find(this.selectedAnomaly.damage.uuid);
        }
      }
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isLoading = false;
    }
  }

  public handlePagination() {
    return debounce(
      (page: number) => {
        this.page = page;
        this.getAnomalies();
      },
      300,
    );
  }

  protected handleFilterChanged() {
    this.resetPagination();
    this.getAnomalies();
  }

  protected resetPagination() {
    this.total = 0;
    this.lastPage = 1;
    this.from = 0;
    this.to = 0;
    this.page = 1;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Monitor' },
        ],
      });
  }

  // #endregion

  // #region Async methods

  protected async updateAnomaly(anomaly: Anomaly, payload: {[key: string]: any}) {
    try {
      const newAnomaly = await new Anomaly(anomaly)
        .include([AnomalyIncludes.USER, AnomalyIncludes.REPORT, AnomalyIncludes.DAMAGE])
        .update(payload);

      const index = this.anomalies?.findIndex((anomalyItem) => anomalyItem.id === newAnomaly.id);

      if (this.anomalies && (index !== undefined) && index >= 0) {
        this.$set(this.anomalies, index, newAnomaly);
      }
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected async getAnomalies(): Promise<void> {
    this.isLoadingAnomalies = true;
    try {
      this.anomalies = await new Anomaly().filter(this.filters).include([AnomalyIncludes.SUMMARY])
        .limit(this.limit)
        .page(this.page)
        .all();
      this.total = this.anomalies[0] !== undefined ? (this.anomalies[0].meta as TableMeta).total : 0;
      this.lastPage = this.anomalies[0] !== undefined ? (this.anomalies[0].meta as TableMeta).last_page : 1;
      this.from = this.anomalies[0] !== undefined ? (this.anomalies[0].meta as TableMeta).from : 0;
      this.to = this.anomalies[0] !== undefined ? (this.anomalies[0].meta as TableMeta).to : 0;
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isLoadingAnomalies = false;
    }
  }

  // #endregion

  // #region Getters & Setters

  protected get AnomalyTypeLabels(): {[key: string] :string} {
    return AnomalyTypeLabels;
  }

  protected get filters(): AnomaliesFilter {
    return {
      is_applicable: true,
      statuses: this.showApproved ? [AnomalyStatusEnum.APPROVED] : [AnomalyStatusEnum.PENDING],
      types: this.selectedTypes,
    };
  }

  // #endregion
}

interface AnomaliesFilter {
  is_applicable?: boolean,
  statuses?: AnomalyStatus[]
  types?: AnomalyType[],
}
