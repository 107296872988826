var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "imageSlider" }, [
    _c(
      "div",
      { staticClass: "imageSlider_buttonWrapper prev" },
      [
        _c(
          "v-icon",
          {
            staticClass: "imageSlider_button",
            nativeOn: {
              click: function($event) {
                return _vm.goToPrevSlide()
              }
            }
          },
          [_vm._v("navigate_before")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tw-flex tw-justify-center" },
      [
        _vm._l(_vm.images, function(image, i) {
          return [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeIndex === i,
                  expression: "activeIndex === i"
                }
              ],
              key: i,
              staticClass: "image_slide",
              attrs: { src: image.original }
            })
          ]
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "imageSlider_buttonWrapper next" },
      [
        _c(
          "v-icon",
          {
            staticClass: "imageSlider_close",
            nativeOn: {
              click: function($event) {
                return _vm.close()
              }
            }
          },
          [_vm._v("close")]
        ),
        _c(
          "v-icon",
          {
            staticClass: "imageSlider_button",
            nativeOn: {
              click: function($event) {
                return _vm.goToNextSlide()
              }
            }
          },
          [_vm._v("navigate_next")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "imageDelimiter" },
      [
        _vm._l(_vm.images, function(image, i) {
          return [
            _c(
              "div",
              {
                key: i,
                staticClass: "image_thumbWrapper",
                class: { active: _vm.activeIndex === i },
                on: {
                  click: function($event) {
                    return _vm.goToSlide(i)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "image_thumb",
                  attrs: { src: image.thumb }
                })
              ]
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }