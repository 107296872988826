import { Media } from '@/models/Report';
import { ADialog } from '@/support/ADialog';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<ImageSlider>({})
export default class ImageSlider extends Vue {
  @Prop()
  protected images!: Media[];

  @Prop({ default: 0 })
  protected index!: number;

  protected activeIndex = 0;

  protected mounted(): void {
    this.activeIndex = this.index;
  }

  protected goToPrevSlide(): void {
    if (this.activeIndex === 0) {
      this.activeIndex = this.images.length - 1;
      return;
    }

    this.activeIndex -= 1;
  }

  protected goToSlide(index: number): void {
    this.activeIndex = index;
  }

  protected goToNextSlide(): void {
    if (this.activeIndex === (this.images.length - 1)) {
      this.activeIndex = 0;
      return;
    }

    this.activeIndex += 1;
  }

  protected close() {
    this.$emit('close');
    ADialog.close();
  }
}
