var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "monitorTool" }, [
    _c("div", { staticClass: "monitorTool_infoPanels" }, [
      _c(
        "div",
        {
          staticClass: "tw-w-1/6 tw-pr-16 tw-pb-24",
          attrs: { title: "Expert" }
        },
        [
          _c("ExpertInfo", {
            attrs: {
              expert: _vm.selectedAnomaly ? _vm.selectedAnomaly.user : "",
              loading: _vm.isLoading
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tw-w-5/6 tw-pl-16" },
        [
          _c("ReportInfo", {
            attrs: {
              anomaly: _vm.selectedAnomaly,
              report: _vm.selectedReport,
              damage: _vm.selectedDamage,
              loading: _vm.isLoading
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "monitorTool_anomalies" },
      [
        _c(
          "ACard",
          {
            staticClass: "tw-relative tw-w-full tw-mt-8",
            attrs: { title: "Afwijkingen" }
          },
          [
            _c(
              "template",
              { slot: "actions" },
              [
                _c("v-checkbox", {
                  staticClass: "tw-mt-0",
                  attrs: {
                    color: "primary",
                    label: "Goedgekeurde afwijkingen tonen",
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.handleFilterChanged()
                    }
                  },
                  model: {
                    value: _vm.showApproved,
                    callback: function($$v) {
                      _vm.showApproved = $$v
                    },
                    expression: "showApproved"
                  }
                })
              ],
              1
            ),
            _c(
              "AStatefulFormForFilters",
              [
                _c("ASelectInput", {
                  attrs: {
                    clearable: "",
                    multiple: "",
                    "hide-details": "",
                    "item-text": "label",
                    "item-value": "value",
                    items: _vm.anomalyTypes,
                    label: "Criteria"
                  },
                  on: {
                    change: function($event) {
                      return _vm.handleFilterChanged()
                    }
                  },
                  model: {
                    value: _vm.selectedTypes,
                    callback: function($$v) {
                      _vm.selectedTypes = $$v
                    },
                    expression: "selectedTypes"
                  }
                })
              ],
              1
            ),
            _c(
              "v-layout",
              { staticClass: "anomalyList overflow-auto" },
              [
                _vm.isLoadingAnomalies
                  ? [_c("ALoader", { staticClass: "tw-h-full tw-flex" })]
                  : _vm._e(),
                !_vm.isLoadingAnomalies && _vm.anomalies.length > 0
                  ? [
                      _c(
                        "table",
                        {
                          staticClass: "item__list item__list--table tw-mb-16"
                        },
                        [
                          _c("thead", [
                            _c(
                              "tr",
                              {
                                staticClass:
                                  "label header__row tw-text-black-400",
                                attrs: { wrap: "", row: "", "align-center": "" }
                              },
                              [
                                _c("td", [_vm._v("Datum / tijdstip")]),
                                _c("td", [_vm._v("Deskundige")]),
                                _c("td", [_vm._v("Dossier")]),
                                _c("td", { staticClass: "tw-max-w-sm" }, [
                                  _vm._v("Schade")
                                ]),
                                _c("td", [_vm._v("Criteria")]),
                                _c("td", [_vm._v("Informatie")]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v("PGV (1%)")
                                ]),
                                _c("td", [_vm._v("Goedkeuren")]),
                                _c("td")
                              ]
                            )
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.anomalies, function(anomaly, index) {
                              return _c(
                                "tr",
                                {
                                  key: index,
                                  staticClass:
                                    "\n                  list__item\n                  tw-cursor-pointer\n                  hover:tw-bg-gray-200\n                  tw-transition-colors tw-ease-in-out tw-duration-300\n                ",
                                  class: [
                                    _vm.selectedAnomaly === anomaly
                                      ? "tw-bg-gray-200"
                                      : "",
                                    anomaly.isApproved
                                      ? "tw-text-black-200"
                                      : "tw-text-black-900"
                                  ],
                                  on: {
                                    click: function($event) {
                                      return _vm.selectAnomaly(anomaly)
                                    }
                                  }
                                },
                                [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormatTime")(
                                          anomaly.created_at
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        anomaly.summary &&
                                          anomaly.summary.expert
                                          ? anomaly.summary.expert.name
                                          : ""
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        anomaly.summary &&
                                          anomaly.summary.report
                                          ? anomaly.summary.report.case_number
                                          : ""
                                      )
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "tw-max-w-sm tw-whitespace-normal"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            anomaly.summary &&
                                              anomaly.summary.damage
                                              ? anomaly.summary.damage.name
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.AnomalyTypeLabels[anomaly.type]
                                      )
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c("AnomalyCustomProperties", {
                                        attrs: { anomaly: anomaly }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          anomaly.summary &&
                                            anomaly.summary.quake &&
                                            anomaly.summary.quake.pgv_1pct
                                            ? anomaly.summary.quake.pgv_1pct
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "tw-flex" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-checkbox",
                                                        _vm._g(
                                                          {
                                                            key: anomaly.id,
                                                            staticClass:
                                                              "tw-mt-0",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              "false-value": false,
                                                              "true-value": true,
                                                              "input-value":
                                                                anomaly.isApproved,
                                                              "hide-details": ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.updateAnomaly(
                                                                  anomaly,
                                                                  {
                                                                    status: anomaly.isApproved
                                                                      ? "pending"
                                                                      : "approved"
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Afwijking goedkeuren")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("td")
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            !_vm.isLoadingAnomalies
              ? [
                  _c("div", { staticClass: "tableFooter" }, [
                    _c("span", { staticClass: "tw-self-center" }, [
                      _vm._v(
                        _vm._s(_vm.from) +
                          " - " +
                          _vm._s(_vm.to) +
                          " van " +
                          _vm._s(_vm.total)
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-starving" },
                      [
                        _c("v-pagination", {
                          attrs: { length: _vm.lastPage, "total-visible": 7 },
                          on: { input: _vm.paginationDebounce },
                          model: {
                            value: _vm.page,
                            callback: function($$v) {
                              _vm.page = $$v
                            },
                            expression: "page"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              : _vm._e(),
            !_vm.isLoadingAnomalies && !_vm.anomalies.length
              ? [
                  _c("div", { staticClass: "tw-h-full tw-w-full tw-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-w-full tw-text-center tw-mb-64 tw-self-center"
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass:
                              "tw-block tw-text-black-300 tw-text-7xl tw-mb-16"
                          },
                          [_vm._v("error_outline")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "tw-block tw-text-black-300 tw-text-lg tw-font-semibold"
                          },
                          [_vm._v(" Er zijn geen afwijkingen gevonden. ")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              : _vm._e()
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }