import { Damage } from '@/models/Damage';
import { Report } from '@/models/Report';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Questionnaire from '@/components/Questionnaire/Questionnaire.vue';
import { Question } from '@/models/Question';
import Storage from '@/support/Storage';
import { ADialog } from '@/support/ADialog';
import ImageSlider from '@/components/ImageSlider/ImageSlider.vue';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { overwriteConclusionReasonLabels } from '@/items/OverwriteConclusionDialogReasons';
import { Anomaly, AnomalyType } from '@/models/Anomaly';
import AnomalyCustomProperties from '@/components/AnomalyCustomProperties/AnomalyCustomProperties.vue';
import RepairTable from '@/components/RepairTable/RepairTable.vue';
import { getStatusColor, getStatusLabel } from '@/support/ReportStatus';

@Component<ReportInfo>({
  components: {
    Questionnaire,
    AnomalyCustomProperties,
    RepairTable,
  },
})
export default class ReportInfo extends Vue {
  @Prop()
  protected anomaly!: Anomaly | null;

  @Prop()
  protected report!: Report | null;

  @Prop()
  protected damage!: Damage | null;

  @Prop({ default: false })
  protected loading!: boolean;

  protected isLoadingQuestions = true;

  protected damageQuestions: Question[] = [];

  protected activeDamageTab = 'deviant';

  protected activeReportTab = 'info';

  protected databaseDate = '0000-00-00 00:00:00';

  protected async loadDamageQuestions() {
    if (! this.report || ! this.damage) {
      return;
    }

    this.isLoadingQuestions = true;

    this.damageQuestions = await new Question()
      .filter({
        form_types: ['manager', 'tcmg', 'expert', 'pre_controller'],
        sections: new Report(this.report).isRegulier2021Report ? 121 : 51,
        report_type: this.report.type?.uuid,
        report: this.report.uuid,
      })
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoadingQuestions = false;
  }

  protected navigateToDamage(): void {
    Storage.set('expert_currentDamageId', `${this.damage?.uuid}`);
    Storage.set('expert_activeTab', 'tab-schades');
    Storage.set('expert_activeTabSub', 'tab-report');
    const routeData = this.$router.resolve(`/expert/${this.report?.uuid}`);
    window.open(routeData.href, '_blank');
  }

  protected openImageSlider(index: number): void {
    ADialog.open(ImageSlider, {
      props: {
        index,
        images: this.damage?.media,
      },
    });
  }

  protected getVatText() {
    if (! this.report || ! this.report.calculation_model_vat_low) {
      return '';
    }

    return `met ${this.report.calculation_model_vat_low}% BTW`;
  }

  protected getStatusColor(key: string) {
    return getStatusColor(key);
  }

  protected getStatusLabel(key: string) {
    return getStatusLabel(key);
  }

  protected get damageTabs() {
    return [
      {
        name: 'Afwijking',
        key: 'deviant',
      },
      {
        name: 'Foto\'s',
        key: 'pictures',
      },
      {
        name: 'Vragenlijst',
        key: 'questionnaire',
      },
      {
        name: 'Reparaties',
        key: 'repairs',
      },
    ];
  }

  protected get reportTabs() {
    return [
      {
        name: 'Informatie',
        key: 'info',
      },
      {
        name: 'Kaart',
        key: 'map',
        hidden: ! this.hasValidAddress,
      },
    ];
  }

  protected get overwriteConclusionReasonLabels() {
    return overwriteConclusionReasonLabels;
  }

  protected get hasValidAddress(): boolean {
    return !! (this.report && this.report.address && this.report.address.geolocation && this.report.address.geolocation.latitude && this.report.address.geolocation.longitude);
  }

  protected get AnomalyType(): {[key: string] :string} {
    return AnomalyType;
  }

  @Watch('report')
  public reportChanged(newReport: Report, oldReport: Report): void {
    if (! oldReport || (newReport.type?.uuid != oldReport.type?.uuid)) {
      this.loadDamageQuestions();
    }
  }

  @Watch('damage')
  public damageChanged(newDamage: Damage, oldDamage: Damage): void {
    if (! oldDamage || (newDamage.uuid != oldDamage.uuid)) {
      this.loadDamageQuestions();
    }
  }
}
